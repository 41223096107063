<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('bulk_graduation')"/>
            </template>
            <template v-slot:header-mobile>
                <header-mobile :title="$t('bulk_graduation')"/>
            </template>
            <div>
                <div class="border rounded-sm p-3 p-lg-5 mb-3 datatable-filter">
                    <div class="d-flex flex-column flex-xl-row">
                        <div class="flex-grow-1 mr-lg-4">
                            <ValidationObserver ref="formModalValidate">
                                <b-row>
                                    <b-col sm="3">
                                        <ValidationProvider name="student_numbers"
                                                            :rules="showFaculty?'':'required'" v-slot="{errors}">
                                            <b-form-group :label="$t('student_number')">
                                                <lined-textarea
                                                    v-model="formData.filter.student_numbers"
                                                    :nowrap="false"
                                                    :disabled="false"
                                                    :styles="{ height: '184px' }"
                                                />
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="9">
                                        <ValidationProvider name="faculty_code" :rules="showFaculty?'required':''"
                                                            v-slot="{errors}">
                                            <b-form-group :label="$t('faculty')">
                                                <faculty-selectbox v-model="formData.filter.faculty_code"
                                                                   :validate-error="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <ValidationProvider name="department_code" :rules="showFaculty?'required':''"
                                                            v-slot="{errors}">
                                            <b-form-group :label="$t('department')">
                                                <department-selectbox v-model="formData.filter.department_code"
                                                                      :faculty_code="formData.filter.faculty_code"
                                                                      :validate-error="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <ValidationProvider name="program_code" :rules="showFaculty?'required':''"
                                                            v-slot="{errors}">
                                            <b-form-group :label="$t('program')">
                                                <program-selectbox v-model="formData.filter.program_code"
                                                                   :faculty_code="formData.filter.faculty_code"
                                                                   :department_code="formData.filter.department_code"
                                                                   :validate-error="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="9">
                                        <processing-button @click="showModal" :label="'start_proccess'"/>
                                    </b-col>
                                </b-row>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div>
            <CommonModal ref="graduation" size="md" :on-hide-only-x="true" @bHideModalHeaderClose="closeModal">
                <template v-slot:CommonModalTitle>
                    {{ $t('graduation_information') }}
                </template>
                <template v-slot:CommonModalContent v-if="process=='graduation'">
                    <ValidationObserver ref="formGraduationValidate">
                        <ValidationProvider name="graduation_decision_date" rules="required" v-slot="{errors}">
                            <b-form-group :label="$t('graduation_decision_date')">
                                <select-date v-model="formData.data.graduation_decision_date"
                                             :validation-error="errors[0]"/>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="graduation_decision_number" rules="required" v-slot="{errors}">
                            <b-form-group :label="$t('graduation_decision_number')">
                                <b-form-input type="number" v-model="formData.data.graduation_decision_number"
                                />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                            </b-form-group>
                        </ValidationProvider>
                        <processing-button @click="save" :processing="loading" :label="'save'"/>
                    </ValidationObserver>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>

<script>
import AppLayout from "@/layouts/AppLayout.vue";
import Header from "@/layouts/AppLayout/Header.vue";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile.vue";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox.vue";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox.vue";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox.vue";
import CommonModal from "@/components/elements/CommonModal.vue";
import SelectDate from "@/components/interactive-fields/SelectDate.vue";
import LinedTextarea from "@/components/elements/LinedTextarea.vue";
import ProcessingButton from "@/components/elements/ProcessingButton.vue";

import StudentProgramService from "@/services/StudentProgramService";

import {ValidationObserver, ValidationProvider} from "vee-validate";


export default {
    components: {
        ProcessingButton,
        HeaderMobile,
        Header,
        AppLayout,

        SelectDate,
        ProgramSelectbox,
        DepartmentSelectbox,
        FacultySelectbox,
        LinedTextarea,

        CommonModal,

        ValidationObserver,
        ValidationProvider

    },
    data() {
        return {
            formData: {
                filter: {
                    student_numbers: '',
                    faculty_code: null,
                    department_code: null,
                    program_code: null,
                },
                data: {
                    graduation_decision_date: null,
                    graduation_decision_number: null
                },
            },
            process: null,
            loading: false,
            rules1: false,
            showFaculty: false,
        }
    },

    watch: {
        'formData.filter.student_numbers'(newValue) {
            newValue ? this.showFaculty = false : this.showFaculty = true
        }

    },
    methods: {
        async showModal() {
            const isValidate = await this.$refs.formModalValidate.validate();
            if (isValidate) {
                this.process = 'graduation'
                this.$refs.graduation.$refs.commonModal.show()
            }
        },
        closeModal() {
            this.process = null;
            this.$refs.graduation.$refs.commonModal.hide()
        },
        async save() {
            const isValidate = await this.$refs.formGraduationValidate.validate();
            if (isValidate) {
                let data = {}
                data = {
                    filter: {
                        student_numbers: '',
                        faculty_code: this.formData.filter.faculty_code,
                        department_code: this.formData.filter.department_code,
                        program_code: this.formData.filter.program_code,
                    },
                    data: {
                        graduation_decision_date: this.formData.data.graduation_decision_date,
                        graduation_decision_number: this.formData.data.graduation_decision_number
                    }
                }
                if (this.formData.filter.student_numbers) {
                    data.filter.student_numbers = this.formData.filter.student_numbers.split('\n')
                }

                this.loading = true;
                StudentProgramService.bulkGraduate(data)
                    .then((response) => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                        this.closeModal()
                    })
                    .catch(err => {
                        this.showErrors(err, this.$refs.graduation);
                        this.closeModal();
                    })
            }

        }
    }

}
</script>
